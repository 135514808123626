<template>
  <v-app fluid class="application">
    <Sidebar :drawer="showSidebar" v-if="hasChosenOrganisation" :key="orgId" />
    <Navigation @drawer="(val) => (showSidebar = val)" v-show="hasChosenOrganisation" />
    <v-main :class="{ login: !hasChosenOrganisation, loggedIn: hasChosenOrganisation }">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <Snackbar :config="snackbarConfig" />
  </v-app>
</template>

<script>
import Sidebar from "./components/Navigation/Sidebar";
import { mapActions, mapState } from "vuex";
import { ACTION_EDIT_SENDER_INFO } from "./store/constants/user";
import Snackbar from "./components/Common/Snackbar";

const Navigation = () => import("./components/Navigation/Navigation");

// noinspection JSUnusedGlobalSymbols
export default {
  name: "App",
  components: {
    Snackbar,
    Sidebar,
    Navigation,
  },
  data() {
    return {
      showSidebar: true,
      snackbarConfig: {
        text: "Session expired",
        color: "error",
        snackbar: false,
      },
    };
  },
  methods: {
    ...mapActions({
      setSenderData: ACTION_EDIT_SENDER_INFO,
    }),
  },
  watch: {
    sessionExpiration() {
      this.snackbarConfig.text = "Session expired";
      this.snackbarConfig.snackbar = true;
    },
    rateLimited() {
      this.snackbarConfig.text = "Too many failed login attempts. Try again later";
      this.snackbarConfig.snackbar = true;
    },
  },
  computed: {
    ...mapState({
      hasChosenOrganisation: (state) => state.user.hasChosenOrganisation,
      sessionExpiration: (state) => state.user.sessionExpired,
      rateLimited: (state) => state.user.rateLimited,
      userId: (state) => state.user.userID,
      userEmail: (state) => state.user.email,
      userPermissions: (state) => state.user.permissions,
      orgId: (state) => state.user.organisationId,
    }),
  },
};
</script>
<style lang="scss">
body::-webkit-scrollbar {
  display: none !important;
}

@-moz-document url-prefix() {
  * {
    font-weight: 600 !important;
    scrollbar-width: none;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

#nav {
  z-index: 2000 !important;
  background: red;
}

#red {
  background: #000;
}

.login {
  padding: 0;
  background: #fff;
}

.loggedIn {
  background: #fafafa;
}

.pencil {
  cursor: pointer;

  i {
    pointer-events: none;
  }

  &:hover {
    i {
      color: #4caf50 !important;
      transform: scale(1.1);
    }
  }
}

.delete {
  @extend .pencil;

  &:hover {
    i {
      color: red !important;
      transform: scale(1.1);
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.v-switch-reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
</style>
