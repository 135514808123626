import axios from "../axios.service";
import moment from "moment";

const INIT = {
  histogramDeposit: `after=${moment().subtract(1, "months").unix()}&groupByCriteria=DAY`,
  depositsTable: `after=${moment().subtract(1, "months").unix()}`,
  histogramWithdrawal: `after=${moment().subtract(1, "months").unix()}&groupByCriteria=DAY`,
  withdrawalsTable: `after=${moment().subtract(1, "months").unix()}`,
};

class Statistics {
  constructor() {
    this.businessId = null;
  }

  setID(id) {
    this.businessId = id;
    return this;
  }

  async businessBalances() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_URI}balances`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async organisation(payload) {
    try {
      let histogramDeposits = axios.get(
        `${process.env.VUE_APP_URI}statistics/deposits/histogram${payload.histogramDeposit}`
      );
      let histogramWithdrawals = axios.get(
        `${process.env.VUE_APP_URI}statistics/withdrawals/histogram${payload.histogramWithdrawal}`
      );
      let depositsTable = axios.get(`${process.env.VUE_APP_URI}statistics/deposits${payload.depositsTable}`);
      let withdrawalsTable = axios.get(`${process.env.VUE_APP_URI}statistics/withdrawals${payload.withdrawalsTable}`);
      let balance = axios.get(`${process.env.VUE_APP_URI}balances/total`);

      let result = await Promise.all([
        histogramDeposits,
        histogramWithdrawals,
        depositsTable,
        withdrawalsTable,
        balance,
      ]);

      let normalizer = new DataNormalize();
      let coinsTable = normalizer.coinsTable({
        deposits: await result[2].data,
        withdrawals: await result[3].data["withdrawals"],
      });
      let normalizedBalanceData = normalizer.balance(await result[4].data);
      let histogram = {
        deposits: await result[0].data,
        withdrawals: await result[1].data,
      };

      return {
        balance: normalizedBalanceData,
        coinsTable,
        histogram,
      };
    } catch (error) {
      throw error;
    }
  }

  async business(payload = INIT) {
    try {
      if (!this.businessId) throw "Invalid business ID";
      let histogramDeposits = axios.get(
        `${process.env.VUE_APP_URI}statistics/deposits/histogram?businessId=${this.businessId}&${payload.histogramDeposit}`
      );
      let histogramWithdrawals = axios.get(
        `${process.env.VUE_APP_URI}statistics/withdrawals/histogram?businessId=${this.businessId}&${payload.histogramWithdrawal}`
      );
      let depositsTable = axios.get(
        `${process.env.VUE_APP_URI}statistics/deposits?businessId=${this.businessId}&${payload.depositsTable}`
      );
      let withdrawalsTable = axios.get(
        `${process.env.VUE_APP_URI}statistics/withdrawals?businessId=${this.businessId}&${payload.withdrawalsTable}`
      );
      let balance = axios.get(`${process.env.VUE_APP_URI}balances/${this.businessId}`);

      let result = await Promise.all([
        histogramDeposits,
        histogramWithdrawals,
        depositsTable,
        withdrawalsTable,
        balance,
      ]);

      let normalizer = new DataNormalize();
      let coinsTable = normalizer.coinsTable({
        deposits: await result[2].data,
        withdrawals: await result[3].data["withdrawals"],
      });
      let normalizedBalanceData = await result[4].data;

      let histogram = {
        deposits: await result[0].data,
        withdrawals: await result[1].data,
      };

      return {
        balance: normalizedBalanceData,
        coinsTable,
        histogram,
      };
    } catch (error) {
      throw error;
    }
  }
}

class DataNormalize {
  balance(payload) {
    let res = [];
    let settlementTotal = payload.settlementBalances;
    let displayTotal = payload.uniformBalances;

    Object.keys(settlementTotal).forEach((key, index) => {
      let obj = {};
      obj.settlementAmount =
        key === "eur" ? settlementTotal[key].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (+settlementTotal[key]).toFixed(4);
      obj.displayAmount = displayTotal[Object.keys(displayTotal)[index]].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      obj.settlementCurrency = key.toUpperCase();
      res.push(obj);
    });

    return res;
  }

  coinsTable(payload) {
    let coinsTable = {
      deposits: {
        data: [],
        total: 0,
      },
      withdrawals: {
        data: [],
        total: 0,
      },
    };
    payload.deposits = payload.deposits.sort((a, b) => b.deposits - a.deposits);
    payload.withdrawals = payload.withdrawals.sort((a, b) => b.withdrawals - a.withdrawals);

    let totalDeposits = 0;
    let totalWithdrawals = 0;
    let finalDeposits = [];
    let finalWithdrawals = [];
    let depositsOther = {
      coin: "Other",
      deposits: "",
      percent: null,
      volume: "-",
    };
    let withdrawalsOther = {
      coin: "Other",
      withdrawals: "",
      percent: 0,
      volume: "-",
    };
    let otherDepositsTotal = 0;
    let otherWithdrawalsTotal = 0;

    for (let index = 0; index < payload.deposits.length; index++) {
      totalDeposits += payload.deposits[index].deposits;
    }
    for (let index = 0; index < payload.withdrawals.length; index++) {
      totalWithdrawals += payload.withdrawals[index].withdrawals;
    }

    for (let index = 0; index < payload.deposits.length; index++) {
      if (index < 5) {
        payload.deposits[index].percent = ((payload.deposits[index].deposits / totalDeposits) * 100).toFixed(2);
        finalDeposits.push(payload.deposits[index]);
      } else {
        otherDepositsTotal += +payload.deposits[index].deposits;
        depositsOther.percent += +((payload.deposits[index].deposits / totalDeposits) * 100).toFixed(2);
      }
    }

    for (let index = 0; index < payload.withdrawals.length; index++) {
      if (index < 5) {
        payload.withdrawals[index].percent = (
          (payload.withdrawals[index].withdrawals / totalWithdrawals) *
          100
        ).toFixed(2);
        finalWithdrawals.push(payload.withdrawals[index]);
      } else {
        otherWithdrawalsTotal += +payload.withdrawals[index].withdrawals;
        withdrawalsOther.percent += +((payload.withdrawals[index].withdrawals / totalWithdrawals) * 100).toFixed(2);
      }
    }

    finalDeposits.sort((a, b) => b.deposits - a.deposits);
    coinsTable.deposits.total = totalDeposits;
    coinsTable.deposits.data = finalDeposits;

    finalWithdrawals.sort((a, b) => b.withdrawals - a.withdrawals);
    coinsTable.withdrawals.total = totalWithdrawals;
    coinsTable.withdrawals.data = finalWithdrawals;

    if (payload.deposits.length > 5) {
      depositsOther.deposits = otherDepositsTotal;
      coinsTable.deposits.data.push(depositsOther);
    }
    if (payload.withdrawals.length > 5) {
      withdrawalsOther.withdrawals = otherWithdrawalsTotal;
      coinsTable.withdrawals.data.push(withdrawalsOther);
    }
    return coinsTable;
  }
}

export default new Statistics();
