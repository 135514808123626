import * as types from "../types";
import store from "../store";
import { ACTION_LOGOUT } from "../constants/user";
import Business from "../../services/api/business";
import { sortCrypto } from "@/services/sortCurrencies";
import router from "@/router";

const state = {
  cashedBusinessId: false,
  chosenBusiness: "",
  businesses: [],
  supportedCurrencies: [],
  selectedBusiness: null,
};

const mutations = {
  [types.CHOSEN_BUSINESS]: (state, payload) => {
    state.selectedBusiness = payload.app;
    state.cashedBusinessId = payload.isCached;
    state.chosenBusiness = payload.id;
  },
  [types.STORE_SUPPORTED_CURRENCIES]: (state, payload) => {
    state.supportedCurrencies = payload;
  },
};

const actions = {
  [types.ACTION_CHOSEN_BUSINESS]({ commit }, payload) {
    commit(types.CHOSEN_BUSINESS, payload);
  },
  [types.ACTION_REDIRECT_BUSINESS]({ commit }, { paymentType, businessId }) {
    router
      .push({
        name: `business-${paymentType}`,
        params: { id: businessId },
      })
      .catch((err) => {});
  },

  //GET SUPPORTED CURRENCIES
  async [types.ACTION_STORE_SUPPORTED_CURRENCIES]({ commit }) {
    try {
      const supportedCurrencies = await Business.getCurrencies();
      commit(types.STORE_SUPPORTED_CURRENCIES, supportedCurrencies.data);
      return true;
    } catch (e) {
      store.dispatch(ACTION_LOGOUT);
    }
  },
};
const getters = {
  getSupportedCoins: (state) => {
    return state.selectedBusiness ? state.selectedBusiness.supportedDepositCurrencies : [];
  },
  getAllSupportedCoins: (state) => {
    const pool = [];
    for (let item in state.supportedCurrencies) {
      pool.push(state.supportedCurrencies[item].abbreviation);
    }
    return pool;
  },
  getAllDisplayCurrencies: (state) => {
    return state.supportedCurrencies.filter((currency) => currency.isFiat);
  },
  cryptoCoins: (state) => {
    return sortCrypto(state.supportedCurrencies.filter((currency) => !currency.isFiat));
  },
  getAllCryptoCoins: (state) => {
    return sortCrypto(state.supportedCurrencies.filter((coin) => !coin.isFiat).map((item) => item.abbreviation));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
