import { TokenService } from "@/services/storage.service";
import * as constants from "../constants/user";
import * as types from "../types.js";
import router from "../../router";
import store from "../store";
import AuthenticationService from "@/services/api/authentication.service";

const state = {
  accessToken: TokenService.getConfig("token"),
  isLoggedIn: false,
  role: TokenService.getConfig("role"),
  twoFactor: TokenService.getConfig("authentication"),
  email: TokenService.getConfig("email"),
  sender: TokenService.getConfig("sender"),
  permissions: TokenService.getConfig("permissions"),
  organisationList: TokenService.getConfig("organisationList"),
  organisationName: TokenService.getConfig("organisation"),
  organisationId: TokenService.getConfig("organisationId"),
  hasChosenOrganisation: TokenService.getConfig("hasChosenOrganisation"),
  userID: TokenService.getConfig("id"),
  sessionExpired: false,
  rateLimited: false,
};

const actions = {
  //LOGIN
  async [constants.ACTION_LOGIN]({ commit }, { payload, isSwitchingOrg = false, orgId }) {
    try {
      const data = isSwitchingOrg
        ? await AuthenticationService.switchOrganisation(orgId)
        : await AuthenticationService.login(payload);
      let userSettings = {
        email: data.email,
        role: data.role,
        organisationList: data.organisationList,
        organisation: data.organisation,
        organisationId: data.organisationId,
        hasChosenOrganisation: data.hasChosenOrganisation,
        permissions: data.permissions,
        token: data.token,
        id: data.id,
        sender: data.sender,
      };
      commit(constants.SET_USER_CONFIG, userSettings);
      commit(constants.CHANGE_AUTH_STATUS, data.authentication);
      store.commit(types.STORE_SUPPORTED_CURRENCIES, data.supportedCurrencies);

      return true;
    } catch (error) {
      if (!isSwitchingOrg) commit(constants.IS_LOGGED_IN, false);
      throw error;
    }
  },

  //Change auth status internal
  [constants.ACTION_CHANGE_AUTH_STATUS]({ commit }, payload) {
    commit(constants.CHANGE_AUTH_STATUS, payload);
  },

  //Check if logged in
  [constants.ACTION_IS_LOGGED_IN]({ commit }, payload) {
    commit(constants.IS_LOGGED_IN, payload);
  },
  [constants.ACTION_SESSION_EXPIRED]({ commit }) {
    commit(constants.SESSION_EXPIRED);
  },
  [constants.ACTION_RATE_LIMIT]({ commit }) {
    commit(constants.RATE_LIMIT);
  },
  [constants.ACTION_HAS_CHOSEN_ORGANISATION]({ commit }, payload) {
    commit(constants.HAS_CHOSEN_ORGANISATION, payload);
  },
  //LOGOUT
  [constants.ACTION_LOGOUT]({ commit }) {
    const isTryingToLogin = !TokenService.getConfig("organisationList") && !state.isLoggedIn;

    AuthenticationService.logout();
    store.dispatch(constants.ACTION_IS_LOGGED_IN, false);
    commit(constants.LOGOUT_SUCCESS);
    if (!isTryingToLogin)
      router.push("/").catch((err) => {
        if (err.name === "NavigationDuplicated") router.go(0);
      });
  },
  [constants.ACTION_EDIT_SENDER_INFO]({ commit }, sender) {
    commit(constants.EDIT_SENDER_INFO, sender);
  },
};

const mutations = {
  //Set user settings
  [constants.SESSION_EXPIRED]: (state) => {
    state.sessionExpired = !state.sessionExpired;
  },
  [constants.RATE_LIMIT]: (state) => {
    state.rateLimited = true;
  },
  [constants.SET_USER_CONFIG]: (state, config) => {
    state.email = config.email;
    state.accessToken = config.token;
    state.organisationName = config.organisation;
    state.organisationId = config.organisationId;
    state.organisationList = config.organisationList;
    state.hasChosenOrganisation = config.hasChosenOrganisation;
    state.permissions = config.permissions;
    state.role = config.role;
    state.userID = config.id;
    state.sender = config.sender;
  },

  //Change auth status internal
  [constants.CHANGE_AUTH_STATUS]: (state, payload) => {
    state.twoFactor = payload;
  },
  [constants.EDIT_SENDER_INFO]: (state, payload) => {
    state.sender = payload;
  },

  //Check if logged in
  [constants.IS_LOGGED_IN]: (state, payload) => {
    state.isLoggedIn = payload;
  },
  [constants.HAS_CHOSEN_ORGANISATION]: (state, payload) => {
    state.hasChosenOrganisation = payload;
    TokenService.editConfig("hasChosenOrganisation", payload);
  },
  //Clear user settings
  [constants.LOGOUT_SUCCESS]: (state) => {
    state.accessToken = "";
    state.role = "";
    state.email = "";
    state.organisationName = "";
    state.hasChosenOrganisation = false;
    state.twoFactor = "";
  },
};
export default {
  state,
  actions,
  mutations,
};
