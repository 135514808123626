<template>
  <v-snackbar
    v-model="config.snackbar"
    :bottom="y === 'bottom'"
    :color="config.color"
    :left="x === 'left'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'">
    {{ config.text }}
  </v-snackbar>
</template>
<script>
export default {
  name: "Snackbar",
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      timeout: 3000,
      x: "right",
      y: "top",
    };
  },
};
</script>
