<template>
  <div>
    <v-layout v-if="!memberOfMultipleOrgsAndPendingChoice" align-center justify-center @keyup.enter="loginRequest">
      <v-flex xs12 sm12 md12 lg12 style="max-width: 350px">
        <div class="logo">
          <img width="160" :src="require(`../../assets/${$AppConfig.loadDomainLogo()}.png`)" id="form" />
        </div>
        <div v-if="isError" class="errors">{{ responseError }}</div>
        <v-form autocomplete="disabled">
          <v-icon slot="prepend" color="red">mdi-plus</v-icon>
          <v-text-field
            id="email"
            autocomplete="email"
            prepend-icon="$vuetify.icons.userDark"
            spellcheck="false"
            label="Email"
            name="loginEmail"
            placeholder=" "
            persistent-placeholder
            :color="$AppConfig.setTheme().primaryColor"
            type="text"
            :rules="!errors.first('loginEmail') ? [true] : [errors.first('loginEmail')]"
            :error-messages="errors.collect('loginEmail')"
            v-model="email"
            v-validate.lazy="{ required: true, email: true }"></v-text-field>
          <v-text-field
            autocomplete="password"
            prepend-icon="$vuetify.icons.lockDark"
            placeholder=" "
            persistent-placeholder
            v-validate.lazy="{ required: true }"
            id="password"
            label="Password"
            name="loginPassword"
            type="password"
            :color="$AppConfig.setTheme().primaryColor"
            :rules="!errors.first('loginPassword') ? [true] : [errors.first('loginPassword')]"
            :error-messages="errors.collect('loginPassword')"
            v-model="password"></v-text-field>
          <v-text-field
            autocomplete="none"
            prepend-icon="$vuetify.icons.fingerprintDark"
            v-validate.lazy="{ numeric: true, length: 6 }"
            id="2fa"
            :color="$AppConfig.setTheme().primaryColor"
            label="Two-factor code"
            name="twoFactor"
            spellcheck="no"
            v-model="twoFactor"
            hint="Required only if enabled"
            :rules="!errors.first('twoFactor') ? [true] : [errors.first('twoFactor')]"
            :error-messages="errors.collect('twoFactor')"></v-text-field>
        </v-form>
        <v-card-actions class="mt-3">
          <v-spacer></v-spacer>
          <div class="holder">
            <div @click="$router.push('/forgot-password')">Forgot your password?</div>
            <v-btn dark :color="$AppConfig.setTheme().primaryColor" width="100" @click="loginRequest">Login</v-btn>
          </div>
        </v-card-actions>
      </v-flex>
    </v-layout>
    <v-layout v-else align-center justify-center>
      <OrganisationSwitcher />
    </v-layout>
    <v-overlay z-index="555" opacity=".7" :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ACTION_HAS_CHOSEN_ORGANISATION, ACTION_IS_LOGGED_IN, ACTION_LOGIN } from "@/store/constants/user";
import { getErrorMessage } from "@/services/errMessageGenerator";
import OrganisationSwitcher from "@/components/Navigation/OrganisationSwitcher.vue";
import { TokenService } from "@/services/storage.service";
import router from "@/router";
import { determineDefaultRoute } from "@/services/permissionHelper";

export default {
  name: "Home",
  components: { OrganisationSwitcher },
  data() {
    return {
      load: false,
      isError: false,
      responseError: "",
      email: "",
      password: "",
      twoFactor: undefined,
      hasMultipleOrganisations: false,
      organisationList: [],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn,
    }),
    memberOfMultipleOrgsAndPendingChoice() {
      return this.hasMultipleOrganisations && !TokenService.getConfig("hasChosenOrganisation");
    },
  },
  methods: {
    ...mapActions({
      login: ACTION_LOGIN,
      setLoggedIn: ACTION_IS_LOGGED_IN,
      setHasChosenOrganisation: ACTION_HAS_CHOSEN_ORGANISATION,
    }),
    async loginRequest() {
      this.load = true;
      this.isError = false;
      const validator = await this.$validator.validate();
      if (validator) {
        try {
          let payload = {
            email: this.email.toLowerCase(),
            password: this.password,
          };
          if (this.twoFactor !== undefined) {
            payload.twoFaCode = +this.twoFactor;
          }
          await this.login({ payload });
          this.organisationList = TokenService.getConfig("organisationList");
          if (this.organisationList.length === 1) {
            await this.setLoggedIn(true);
            await this.setHasChosenOrganisation(true);
            router.push(determineDefaultRoute(TokenService.getConfig("permissions"))).then(() => {});
          } else {
            this.hasMultipleOrganisations = true;
          }
        } catch (error) {
          console.log(error.message);
          if (error.message === "Network Error") {
            this.isError = true;
            this.responseError = `You’ve performed too many login attempts, please try again later.`;
            return;
          }
          this.isError = true;
          this.responseError = getErrorMessage(error, "Error occurred during login");
        } finally {
          this.load = false;
        }
      } else {
        this.load = false;
      }
    },
  },
  mounted() {
    this.hasMultipleOrganisations =
      TokenService.getConfig("organisationList") && TokenService.getConfig("organisationList").length > 1;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/_mixins.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #f9f9f9 inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #f9f9f9 inset !important;
}

.logo {
  @include flexAttr(flex, center, center);
  margin-bottom: 70px;
}

.otherlogos {
  @include adjust-dimensions(132px, 36px);
}

.errors {
  font-size: 12px;
  color: red;
  margin-left: 30px;
  margin-bottom: 15px;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > div {
    font-size: 13px;
    color: #1567a6;
    cursor: pointer;

    &:hover {
      color: #006bc7;
    }
  }
}
</style>