const fiatPredicate = ["EUR", "USD", "GBP", "JPY"];
const cryptoPredicate = ["BTC", "BCH", "LTC", "ETH", "USDT", "XLM", "XRP"];

export function sortFiat(fiats) {
  return sorter(fiats, fiatPredicate);
}

export function sortCrypto(coins) {
  if (coins.length && coins[0] && coins[0].abbreviation) {
    const alphabetically = coins
      .filter((val) => !cryptoPredicate.includes(val.abbreviation))
      .sort((a, b) => (a.abbreviation > b.abbreviation ? 1 : -1));
    const predicated = coins
      .filter((val) => cryptoPredicate.includes(val.abbreviation))
      .sort((a, b) => cryptoPredicate.indexOf(a.abbreviation) - cryptoPredicate.indexOf(b.abbreviation));

    return [...predicated, ...alphabetically];
  }

  return sorter(coins, cryptoPredicate);
}

function sorter(currencies, predicate) {
  const alphabetically = currencies.filter((val) => !predicate.includes(val)).sort();
  const diff = predicate.filter((val) => !currencies.includes(val));
  const filtered = predicate.filter((item) => !diff.includes(item));
  return [...filtered, ...alphabetically];
}
