import AppConfig from "../config/app-config";

export function generateRolePermissions(roles, includeAll = false) {
  roles.forEach((role) => {
    role.humanReadablePermissions = [];
    AppConfig.permissions.forEach((perm) => {
      const isFull = perm.full.every((perm) => role.permissions.includes(perm));
      const isViewOnly = perm.view.length ? perm.view.every((perm) => role.permissions.includes(perm)) : false;

      const access = isFull ? "Full access" : isViewOnly ? "View access" : "No access";

      if (includeAll) {
        role.humanReadablePermissions.push({ name: perm.name, access: access });
      } else {
        if (access !== "No access") role.humanReadablePermissions.push({ name: perm.name, access: access });
      }
    });
  });
  return roles;
}

export function determineDefaultRoute(permissions) {
  if (permissions.includes("GET_STATISTICS") && permissions.includes("GET_BALANCES")) return "/statistics";
  if (permissions.includes("GET_BUSINESSES")) return "/businesses";
  if (permissions.includes("GET_API_CREDENTIALS")) return "/api-keys";
  if (permissions.includes("WHITELIST_ADDRESSES")) return "/whitelisted-addresses";
  if (permissions.includes("GET_USERS")) return "/users";
  if (permissions.includes("MANAGE_ROLES")) return "/user-roles";

  return "/change-password";
}
