<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="d-flex mb-10 align-start">
        <v-icon class="mr-2">$vuetify.icons.businessesDark</v-icon>&nbsp;
        <v-flex>Businesses</v-flex>
        <v-btn
          v-if="hasEditPermissions"
          dark
          :color="$AppConfig.setTheme().positiveButtonAction"
          @click="$router.push(`/create-business`).catch((err) => {})">
          <v-icon :color="$AppConfig.setTheme().buttonTextColor" class="mr-1">mdi-briefcase-plus</v-icon>
          create new business
        </v-btn>
      </v-col>
      <div class="d-flex flex-row flex-wrap justify-center">
        <v-col cols="12" md="12" sm="12" xs="12" lg="12" class="d-flex justify-center" style="max-width: 500px">
          <v-text-field
            :color="$AppConfig.setTheme().primaryColor"
            v-model="searchBusiness"
            style="width: 300px; max-width: 100%"
            label="Search business"
            class="pl-5 pr-5"
            append-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-wrap" style="max-width: 1400px" v-if="businesses.length">
          <v-flex
            md6
            sm12
            pa-3
            v-for="business in filter"
            :key="business.businessId"
            :class="filter.length < 3 ? 'lg7' : 'lg4'">
            <v-card class="my-0 pa-2" elevation="3">
              <v-container fill-height fluid style="min-height: 70px" class="d-flex align-start mb-4">
                <v-layout>
                  <v-flex
                    xs12
                    class="d-flex justify-space-between align-center"
                    style="font-size: 18px; min-height: 55px">
                    <span style="max-width: 70%">{{ business.businessName || business.name }}</span>
                    <v-btn
                      v-if="hasEditPermissions"
                      @click="edit(business.businessId || business.id)"
                      depressed
                      text
                      class="ma-0"
                      style="text-transform: capitalize"
                      :style="{ color: $AppConfig.setTheme().primaryColor }">
                      <v-icon class="mr-1" right dark>mdi-pencil</v-icon>
                      Edit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-card-text class="pt-0 pb-0 mb-4">
                <div class="d-flex">
                  <div class="d-flex flex-column align-center" style="width: 100%">
                    <div>
                      <v-img
                        width="40"
                        :src="
                          require(`../../assets/images/businesses/${business.settlementFiatCurrency.toLowerCase()}.png`)
                        "></v-img>
                    </div>
                    <div
                      class="d-flex flex-column align-center"
                      style="width: 100%; font-size: 14px; font-weight: 500; opacity: 0.77; color: black">
                      <div v-if="business.settlementFiatAmount">
                        {{ `${business.settlementFiatAmount} ${business.settlementFiatCurrency}` }}
                      </div>
                      <div v-else>
                        {{ `*** ${business.settlementFiatCurrency}` }}
                      </div>
                      <div v-if="business.fiatAllocation">
                        {{ (+business.fiatAllocation * 100).toFixed(0) + " %" }}
                      </div>
                      <div v-else></div>
                    </div>
                  </div>
                  <v-divider vertical class="mx-5 mb-0" />

                  <div style="width: 100%" class="d-flex flex-column align-center">
                    <div>
                      <v-img
                        width="40"
                        :src="
                          require(`../../assets/images/${business.settlementCryptoCurrency.toLowerCase()}.png`)
                        "></v-img>
                    </div>

                    <div
                      class="d-flex flex-column align-center"
                      style="width: 100%; font-size: 14px; font-weight: 500; opacity: 0.77; color: black">
                      <div v-if="business.settlementCryptoAmount">
                        {{ `${(+business.settlementCryptoAmount).toFixed(6)} ${business.settlementCryptoCurrency}` }}
                      </div>
                      <div v-else>
                        {{ `*** ${business.settlementCryptoCurrency}` }}
                      </div>
                      <div v-if="business.cryptoAllocation">
                        {{ (+business.cryptoAllocation * 100).toFixed(0) + " %" }}
                      </div>
                      <div v-else></div>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-text></v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  replace
                  dark
                  style="border-radius: 9px; width: 100%"
                  :color="$AppConfig.setTheme().primaryColor"
                  @click="expand(business.businessId || business.id)">
                  <v-icon right dark>mdi-arrow-expand-all</v-icon>
                  Expand business
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-col>
        <v-col cols="12" md="12" v-else>
          <div class="no-payments">
            <img src="../../assets/images/nopayments.png" alt="bitcoin" />
            <div>No businesses created yet</div>
          </div>
        </v-col>
      </div>
    </v-row>
    <v-overlay absolute z-index="222" opacity=".7" :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Snackbar :config="snackbarConfig" />
  </div>
</template>
<script>
import Statistics from "../../services/api/statistics";
import { TokenService } from "@/services/storage.service";
import Snackbar from "@/components/Common/Snackbar";
import { mapState } from "vuex";
import Business from "@/services/api/business";

export default {
  name: "BusinessDashboard",
  components: {
    Snackbar,
  },
  data() {
    return {
      load: true,
      businesses: [],
      searchBusiness: "",
      snackbarConfig: {
        text: "Failed to load businesses",
        color: "error",
        snackbar: false,
      },
    };
  },
  computed: {
    ...mapState({
      userPermissions: (state) => state.user.permissions,
    }),
    hasEditPermissions() {
      return this.userPermissions.includes("CREATE_BUSINESS");
    },
    filter() {
      return this.businesses.filter(
        (business) =>
          business?.businessName?.toLowerCase().includes(this.searchBusiness.toLowerCase()) ||
          business?.name?.toLowerCase().includes(this.searchBusiness.toLowerCase())
      );
    },
  },
  methods: {
    expand(id) {
      this.$router
        .push({
          name: "business",
          params: { id: id },
        })
        .catch((err) => {});
    },
    edit(id) {
      this.$router
        .push({
          name: "edit-business",
          params: { id: id },
        })
        .catch((err) => {});
    },
  },
  async mounted() {
    try {
      if (TokenService.getConfig("permissions").includes("GET_BALANCES")) {
        const balances = await Statistics.businessBalances();
        if (!balances || !balances.length) return (this.businesses = []);
        const sortedByBalance = balances.sort((a, b) => {
          return (
            parseFloat(b.displayCryptoAmount) +
            parseFloat(b.displayFiatAmount) -
            (parseFloat(a.displayCryptoAmount) + parseFloat(a.displayFiatAmount))
          );
        });
        TokenService.getConfig("role") === this.$AppConfig.userRoles.ADMIN
          ? (this.businesses = sortedByBalance)
          : (this.businesses = sortedByBalance.filter(
              (business) => business.businessId !== this.$AppConfig.customers.MALDOPAY_SECRET_BUSINESS_ID
            ));
      } else if (TokenService.getConfig("permissions").includes("GET_BUSINESSES")) {
        const allBusinesses = await Business.getAll();
        this.businesses = allBusinesses.filter(
          (business) => business.id !== this.$AppConfig.customers.MALDOPAY_SECRET_BUSINESS_ID
        );
      }
    } catch (error) {
      this.snackbarConfig.text = error.message;
      this.snackbarConfig.snackbar = true;
    } finally {
      this.load = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.no-payments {
  display: flex;
  flex-direction: column;

  align-items: center;

  img {
    width: 100px;
    height: 100px;
    margin-top: 100px;
  }

  div {
    color: #5ab5ce;

    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
  }
}
</style>