<template>
  <v-navigation-drawer
    ref="drawer"
    id="app-drawer"
    :value="drawer"
    app
    :color="$AppConfig.setTheme().primaryColor"
    floating
    mobile-breakpoint="768"
    width="240"
    style="height: calc(100vh - 64px); margin-top: 64px">
    <div class="sidebar-content-wrapper">
      <v-list-item three-line dark>
        <v-avatar size="34">
          <img src="../../assets/user.svg" alt />
        </v-avatar>
        <v-list-item-title class="ml-3">
          {{ email }}
          <div
            :style="{ color: $AppConfig.setTheme().userRole }"
            style="font-size: 14px; text-transform: capitalize"
            v-if="role">
            {{ role.toLowerCase() }}
          </div>
        </v-list-item-title>
      </v-list-item>

      <v-divider class="mx-2 mb-1" />
      <ul
        v-for="section in Object.keys(generateAvailableItems)"
        :style="{ color: $AppConfig.setTheme().sidebarColor }"
        class="sidebar-nav">
        <h6 class="section-title" :style="{ color: $AppConfig.setTheme().sidebarHeaders }">
          {{ section }}
        </h6>
        <li
          v-for="item in generateAvailableItems[section]"
          :key="item.name"
          :class="[$route.name === item.path ? 'selectedItem-item' : 'sidebar-main-link']"
          @click="navigate(item.path)">
          <v-icon>{{ item.icon }}</v-icon>
          <v-list-item-title class="items-title">
            {{ item.name }}
          </v-list-item-title>
        </li>
      </ul>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ACTION_TOGGLE_SIDEBAR } from "@/store/types";
import { TokenService } from "@/services/storage.service";

export default {
  name: "Sidebar",
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      sidebarStructure: {
        Organisation: [
          {
            name: "Statistics",
            path: "statistics",
            icon: "$vuetify.icons.stats",
            permission: "GET_BALANCES",
          },
          {
            name: "Businesses",
            path: "businesses",
            icon: "$vuetify.icons.businesses",
            permission: "GET_BUSINESSES",
          },
          {
            name: "Bank accounts",
            path: "bank-accounts",
            icon: "$vuetify.icons.bank",
            permission: "FETCH_BANK_METADATA",
          },
          {
            name: "Whitelisted addresses",
            path: "whitelisted-addresses",
            icon: "$vuetify.icons.whitelistedAddresses",
            permission: "WHITELIST_ADDRESSES",
          },
          {
            name: "Withdrawal approvals",
            path: "withdrawal-approvals",
            icon: "$vuetify.icons.withdrawalApprovals",
            permission: "CREATE_WITHDRAWAL",
          },
        ],
        Billing: [
          {
            name: "Crypto deposit",
            path: "crypto-deposit",
            icon: "$vuetify.icons.cryptoDeposit",
            permission: "CREATE_PAYMENT",
          },
          {
            name: "Crypto withdrawal",
            path: "crypto-withdrawal",
            icon: "$vuetify.icons.cryptoWithdrawal",
            permission: "CREATE_WITHDRAWAL",
          },
          {
            name: "Mass Crypto withdrawal",
            path: "mass-withdrawal",
            icon: "$vuetify.icons.cryptoWithdrawal",
            permission: "CREATE_WITHDRAWAL",
            allowedOrganisations: this.$AppConfig.customers.organisationsWithMassWithdrawals,
          },
          {
            name: "Fiat deposit",
            path: "fiat-deposit",
            icon: "$vuetify.icons.fiatDeposit",
            permission: "CREATE_FIAT_DEPOSIT",
          },
          {
            name: "Fiat withdrawal",
            path: "fiat-withdrawal",
            icon: "$vuetify.icons.fiatWithdrawal",
            permission: "CREATE_FIAT_WITHDRAWAL",
          },
        ],
        "Balance management": [
          {
            name: "Internal transfer",
            path: "internal-transfer",
            icon: "$vuetify.icons.internalTransfer",
            permission: "CREATE_INTERNAL_TRANSFER",
          },
          {
            name: "Balance conversion",
            path: "balance-conversion",
            icon: "$vuetify.icons.balanceConversion",
            permission: "CREATE_BALANCE_CONVERSION",
          },
        ],
        Reporting: [
          {
            name: "Balance confirmation",
            path: "balance-confirmation",
            icon: "$vuetify.icons.wallet",
            permission: "GET_BALANCES",
          },
          {
            name: "Transaction ledger",
            path: "transaction-ledger",
            icon: "$vuetify.icons.ledger",
            permission: "GET_BALANCES",
          },
        ],
        Settings: [
          {
            name: "Change password",
            path: "change-password",
            icon: "$vuetify.icons.lock",
            permission: "",
          },
          {
            name: "Two-factor authentication",
            path: "authentication",
            icon: "$vuetify.icons.fingerprint",
            permission: "",
          },
          {
            name: "API keys",
            path: "api-keys",
            icon: "$vuetify.icons.api",
            permission: "GET_API_CREDENTIALS",
          },
          {
            name: "Users",
            path: "users",
            icon: "$vuetify.icons.user",
            permission: "CREATE_USER",
          },
          {
            name: "User roles",
            path: "user-roles",
            icon: "$vuetify.icons.group",
            permission: "MANAGE_ROLES",
          },
        ],
      },
      appId: "",
      inputValue: true,
    };
  },
  methods: {
    ...mapActions({
      toggleSidebar: ACTION_TOGGLE_SIDEBAR,
    }),
    navigate(param) {
      if (this.$route.name === param) return;
      this.$router.push(`/${param}`).catch((err) => {});
    },
    determineAllowedRoutes(routeList) {
      const filteredByOrganisations = routeList.filter((link) =>
        link.allowedOrganisations ? link.allowedOrganisations.includes(this.orgId) : true
      );
      return filteredByOrganisations.filter((link) =>
        link.permission === "" ? true : TokenService.getConfig("permissions").includes(link.permission)
      );
    },
  },
  computed: {
    ...mapState({
      role: (state) => state.user.role,
      userPermissions: (state) => state.user.permissions,
      email: (state) => state.user.email,
      isSidebarOpen: (state) => state.isSidebarOpen,
      orgId: (state) => state.user.organisationId,
    }),
    generateAvailableItems() {
      const availableItems = [];
      Object.keys(this.sidebarStructure).forEach((section) => {
        const currentSectionItems = this.determineAllowedRoutes(this.sidebarStructure[section]);
        if (currentSectionItems.length) availableItems[section] = currentSectionItems;
      });
      return availableItems;
    },
  },
  watch: {
    isSidebarOpen: {
      immediate: true,
      handler(val) {
        this.inputValue = val;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-divider {
  border-color: #456967 !important;
}

* {
  user-select: none;
}

.sidebar-main-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 7px 25px 7px 23px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: flex-end;

  &:hover {
    background: rgba(92, 92, 94, 0.452);
    width: 93%;
    cursor: pointer;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
  }
}

.selectedItem-item {
  @extend .sidebar-main-link;
  background: rgba(92, 92, 94, 0.452);
  width: 93%;
  cursor: pointer;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}

.sidebar-nav {
  list-style: none;
  padding: 24px 0 0 0;
}

.section-title {
  padding: 0 0 12px 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.items-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 10px;
}
</style>