import axios from "../axios.service";
import { TokenService } from "@/services/storage.service";
import Business from "@/services/api/business";

const getOrganisationsAsArrayOfObjects = (orgMap) => {
  const organisationList = [];
  Object.keys(orgMap).forEach((orgId) => organisationList.push({ id: orgId, name: orgMap[orgId] }));
  return organisationList.sort((org1, org2) => (org1.name > org2.name ? 1 : -1));
};

class Authenticator {
  async login(payload) {
    try {
      const userData = await axios.post(`${process.env.VUE_APP_URI}auth/login`, payload);

      const token = userData.headers["x-identity-token"];
      axios.setHeaders(token);

      const { email, role, id, twoFaEnabled: authentication, permissions, organisationId } = userData.data.user;
      const { sessionId } = userData.data;
      const organisationList = getOrganisationsAsArrayOfObjects(userData.data.organisations);

      const organisationName = organisationList.find((el) => el.id === organisationId).name;

      let dataCollection = {
        email,
        id,
        sessionId,
        authentication,
        permissions,
        organisationList,
        hasChosenOrganisation: organisationList.length === 1,
        organisation: organisationName,
        organisationId,
        role,
        token,
        sender: undefined,
      };

      TokenService.saveConfig(dataCollection);

      const supportedCurrencies = await Business.getCurrencies();

      dataCollection.supportedCurrencies = supportedCurrencies.data;
      return dataCollection;
    } catch (error) {
      throw error;
    }
  }

  logout() {
    TokenService.removeConfig();
    axios.removeHeaders();
  }

  async switchOrganisation(orgId) {
    try {
      const result = await axios.post(`${process.env.VUE_APP_URI}auth/login/${orgId}`);

      TokenService.removeConfig();

      const token = result.headers["x-identity-token"];
      axios.setHeaders(token);

      const { email, role, id, twoFaEnabled: authentication, permissions, organisationId } = result.data.user;
      const { sessionId } = result.data;
      const organisationList = getOrganisationsAsArrayOfObjects(result.data.organisations);
      const organisationName = organisationList.find((el) => el.id === organisationId).name;

      let dataCollection = {
        email,
        id,
        sessionId,
        authentication,
        permissions,
        organisationList,
        hasChosenOrganisation: true,
        organisation: organisationName,
        organisationId,
        role,
        token,
        sender: undefined,
      };

      TokenService.saveConfig(dataCollection);

      const supportedCurrencies = await Business.getCurrencies();

      dataCollection.supportedCurrencies = supportedCurrencies.data;
      return dataCollection;
    } catch (e) {
      throw e;
    }
  }

  async manageAuthentication(payload) {
    try {
      return await axios.patch(`${process.env.VUE_APP_URI}users/two-fa`, payload);
    } catch (error) {
      throw error.response;
    }
  }

  async getQR() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}users/two-fa`);
      return data;
    } catch (error) {
      throw error.response;
    }
  }
}

export default new Authenticator();
