export function getErrorMessage(err, fallback) {
  if (typeof err === "string" && err.length > 0) return err;
  if (!fallback) fallback = "Error completing action. Please try again later.";

  return (
    err?.data?.rejection ||
    err?.data?.message ||
    err?.response?.data?.message ||
    err?.response?.data?.rejection ||
    fallback
  );
}
