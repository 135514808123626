<template>
  <div>
    <v-card flat v-if="!hasChosenOrganisation" style="margin-top: 115px">
      <v-card-title class="justify-center">Choose organisation</v-card-title>
      <v-divider></v-divider>
      <v-list width="450px">
        <v-list-item-group>
          <v-list-item
            v-for="org in organisationList"
            :key="org.id"
            @click="switchOrganisation(org.id)"
            class="item-wrapper">
            <v-list-item-avatar class="ml-4 mr-0" min-width="0" width="24">
              <div class="dot" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ org.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions class="d-flex align-center flex-column">
        <v-btn dark :color="$AppConfig.setTheme().primaryColor" width="100" @click="logout">Logout</v-btn>
      </v-card-actions>
      <Snackbar :config="snackbarConfig" />
    </v-card>

    <v-card v-if="hasChosenOrganisation && !load">
      <v-list max-width="250px">
        <v-list-item-group>
          <v-list-item
            v-for="(org, index) in organisationList"
            :key="index"
            :disabled="activeOrganisationId === org.id"
            @click="switchOrganisation(org.id)">
            <v-list-item-avatar class="mr-4">
              <v-avatar :color="$AppConfig.setTheme().positiveButtonAction" size="35">
                <span class="white--text">{{ org.name[0] }}</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ org.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ activeOrganisationId === org.id ? "Currently active" : "Switch to organisation" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions class="d-flex align-center flex-column">
        <v-btn width="100%" dark color="#005550" @click="logout">Logout</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay z-index="555" opacity=".7" :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ACTION_IS_LOGGED_IN, ACTION_LOGIN, ACTION_LOGOUT } from "@/store/constants/user";
import router from "@/router";
import { determineDefaultRoute } from "@/services/permissionHelper";
import { TokenService } from "@/services/storage.service";
import Snackbar from "@/components/Common/Snackbar.vue";
import { getErrorMessage } from "@/services/errMessageGenerator";
import MaterialCard from "@/components/Common/MaterialCard.vue";

export default {
  name: "OrganisationSwitcher",
  components: { MaterialCard, Snackbar },
  data() {
    return {
      snackbarConfig: { text: "", color: "", snackbar: false },
      load: false,
    };
  },
  computed: {
    ...mapState({
      organisationList: (state) => state.user.organisationList,
      organisation: (state) => state.user.organisationName,
      activeOrganisationId: (state) => state.user.organisationId,
      hasChosenOrganisation: (state) => state.user.hasChosenOrganisation,
    }),
  },
  methods: {
    ...mapActions({
      logout: ACTION_LOGOUT,
      login: ACTION_LOGIN,
      setLoggedIn: ACTION_IS_LOGGED_IN,
    }),
    proceedToDashboard() {
      this.setLoggedIn(true);
      return router
        .push(determineDefaultRoute(TokenService.getConfig("permissions")))
        .then(() => {})
        .catch(() => router.go("/"));
    },
    async switchOrganisation(orgId) {
      try {
        this.load = true;
        await this.login({ isSwitchingOrg: true, orgId });
        await this.proceedToDashboard();
      } catch (e) {
        const errorText = getErrorMessage(e, "Failed to switch organisation");
        this.snackbarConfig = {
          text: errorText,
          color: "error",
          snackbar: true,
        };
        this.$emit("throwError", errorText);
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
<style lang="scss">
.dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #d0d7d7;
  margin-right: 7px;
  transition: background-color 0.2s ease-in;
}

.item-wrapper:hover .dot {
  background: #35cb80;
}
</style>
