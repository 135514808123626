<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="380" @click:outside="$emit('closeRequest', true)">
      <v-card class="d-flex flex-column align-center pa-3">
        <v-img class="mt-10" v-if="done" width="100" src="../../assets/images/success_tick.png"> </v-img>
        <v-img v-if="failed" class="mt-10" width="100" src="../../assets/images/exclamation-circle.svg"></v-img>
        <v-card-title
          :style="[
            done
              ? {
                  color: '#242539',
                  fontSize: '18px',
                  lineHeight: '22px',
                  wordBreak: 'break-word',
                }
              : {
                  color: '#242539',
                  fontSize: '18px',
                  lineHeight: '22px',
                  wordBreak: 'break-word',
                },
          ]"
          class="text-center"
          >{{ message }}</v-card-title
        >
        <v-btn
          class="mt-10 mb-5"
          dark
          :color="$AppConfig.setTheme().primaryColor"
          width="200"
          height="50"
          @click="$emit('closeRequest', true)"
          >Close</v-btn
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "ResponseNotifier",
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    appID: {
      type: String,
    },
    addLink: {
      type: Boolean,
    },
    done: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    payments() {
      this.$router
        .push({
          name: "business",
          params: { id: this.appID },
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
#link {
  &:hover {
    cursor: pointer;
  }
}
</style>
