export const dictionary = {
  en: {
    custom: {
      loginEmail: {
        required: "Email is required",
        email: "Please enter a valid email address",
      },
      loginPassword: {
        required: "Password is required",
      },
      url: {
        url: "Invalid URL",
      },
      emailUser: {
        required: "Email is required",
        email: "Please enter a valid email address",
      },
      userPassword: {
        required: "Password is required",
        regex: "At least one uppercase, one lowercase letter and a number",
        min: "At least 8 characters",
      },
      oldPassword: {
        required: "Old password is required",
        regex: "At least one uppercase, one lowercase letter and a number",
        min: "At least 8 characters",
      },
      confirmationPassword: {
        required: "Confirm Password is required",
        is: "Password doesn't match",
      },
      businessName: {
        required: "Name is required",
        regex: "Name should only contain letters,numbers and spaces",
      },
      ip: {
        required: "IP address field is required",
        regex: "Invalid IP address format",
      },
      keyName: {
        required: "Key name is required",
        regex: "Key name should only contain letters,numbers and spaces",
      },
      orgName: {
        required: "Name is required",
        regex: "Name should only contain letters,numbers and spaces",
      },
      withdrawalCallback: {
        regex: "Invalid URL format",
      },
      withdrawalApprovalCallback: {
        regex: "Invalid URL format",
      },
      depositCallback: {
        regex: "Invalid URL format",
      },
      depositCallback2: {
        regex: "Invalid URL format",
      },
      redirectUrlLink: {
        regex: "Invalid URL format",
      },
      paymentID: {
        required: "ID is required",
      },
      amountInternal: {
        regex: "Value must be a number",
        required: "Amount is required",
        decimal: "Amount must have fewer decimal digits",
      },
      token: {
        required: "Token is required",
        numeric: "Only numbers allowed",
        length: "2FA must be 6 digits",
      },
      recipientAddress: {
        required: "Address is required",
      },
      fiatAmount: {
        required: "Amount is required",
        decimal: "Decimal signs exceeded",
        min_value: "Min. value 0.01",
        regex: "Decimal signs exceeded",
        max_value: "Insufficient balance",
      },
      settlementAmount: {
        required: "Amount is required",
        decimal: "Decimal signs exceeded",
        min_value: "Amount is less than minimum",
        max_value: "Exceeded maximum amount",
        regex: "Invalid input",
      },
      fiatAllocation: {
        required: "Field is required",
        numeric: "Only numbers",
        min_value: "Min value is 0",
        max_value: "Max value is 100",
      },
      cryptoAmount: {
        required: "Amount is required",
        min_value: "Value can't be null",
        decimal: "Decimal signs exceeded",
        regex: "Bigger than 0, no comma",
        max_value: "Insufficient balance",
      },
      expiration: {
        min_value: "Min. value 1 min",
        max_value: "Max. value 10080 min (7 days)",
        numeric: "Only numbers allowed",
      },
      destinationTag: {
        numeric: "This field only accepts numbers",
      },
      authentication: {
        required: "Code is required",
        numeric: "Only numbers allowed",
        length: "2FA must be 6 digits",
      },
      twoFactor: {
        numeric: "Only numbers allowed",
        length: "2FA must be 6 digits",
        required: "Code is required",
      },
      recipientEmail: {
        email: "Must be valid email",
        required: "Email is required",
      },
      mailSubject: {
        required: "Mail subject is required",
      },
      senderSignatureName: {
        required: "Signature is required",
      },
      senderVerificationEmail: {
        email: "Must be valid email",
        required: "Email is required",
      },
      senderVerificationName: {
        required: "Name is required",
      },
      senderVerificationAddress: {
        required: "Address is required",
      },
      senderVerificationCity: {
        required: "City is required",
      },
      senderVerificationCountry: {
        required: "Country is required",
      },
      addressId: {
        required: "Address ID is required",
      },
      whitelistAddress: {
        required: "Address is required",
      },
      roleName: {
        required: "Role name is required",
      },
      accountLabel: {
        required: "Account label is required",
      },
      bankAccountHolderName: {
        required: "Bank account holder name is required",
      },
      bankAccountHolderStreet: {
        required: "Street is required",
      },
      bankAccountHolderCity: {
        required: "City is required",
      },
      bankAccountHolderPostalCode: {
        required: "Postal code is required",
      },
      bankAccountHolderCountry: {
        required: "Country is required",
      },
      bankName: {
        required: "Bank name is required",
      },
      bankAddress: {
        required: "Bank address is required",
      },
      bankEmail: {
        required: "Bank email is required for USD accounts",
        email: "Must be a valid email address",
      },
      bankStreet: {
        required: "Street is required",
      },
      bankCity: {
        required: "City is required",
      },
      bankPostalCode: {
        required: "Postal code is required",
      },
      bankCountry: {
        required: "Country is required",
      },
      accountNumber: {
        required: "Account number is required",
      },
      iban: {
        required: "IBAN is required",
      },
      accountCurrency: {
        required: "Currency is required",
      },
      paymentMethod: {
        required: "Payment method is required",
      },
      swiftCode: {
        required: "Swift code is required",
      },
      sortCode: {
        required: "Sort code is required",
      },
      intermediaryBankName: {
        required: "Intermediary bank name is required",
      },
      intermediaryBankAddress: {
        required: "Intermediary bank address is required",
      },
      routingNumber: {
        required: "Routing number is required",
      },
    },
  },
};
