<template>
  <v-col cols="12" class="d-flex flex-column" v-if="availableCryptoCurrenciesAndNetworks.length">
    <v-switch
        :color="$AppConfig.setTheme().primaryColor"
        class="align-self-end"
        density="compact"
        inset
        label="Select all"
        v-model="selectAll"></v-switch>
    <div class="mb-4"
         style="width: 100%;color: rgba(0, 0, 0, 0.87);background: #EEEEEE; border-radius: 8px"
         v-for="currency in availableCryptoCurrenciesAndNetworks" :key="currency.name">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <img class="mr-4 ml-2" width="25px"
               :src="require(`../../assets/images/crypto-svg/${currency.abbreviation.toLowerCase()}.svg`)"
               alt="crypto-currency"/>
          <div>{{ generateLabel(currency) }}</div>
        </div>
        <v-switch
            @change="value=>handleSwitch(value, currency.abbreviation)"
            density="compact"
            inset
            :color="$AppConfig.setTheme().primaryColor"
            v-model="currency.enabled"></v-switch>
      </div>
      <div v-if="currency.enabled" style="width:100%;" class="d-flex flex column">
        <v-checkbox
            class="mr-2 ml-2"
            :color="$AppConfig.setTheme().primaryColor"
            :disabled="currency.networks.length===1"
            v-for="network in currency.networks" :key="network.name"
            v-model="network.enabled"
            :label="network.name">
        </v-checkbox>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "DepositCurrenciesNetworksConfiguration",
  props: {
    supportedDepositCurrencies: {
      type: Array
    },
    preselectedDepositCurrenciesAndNetworks: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      availableCryptoCurrenciesAndNetworks: [],
      selectAll: false,
    }
  },
  watch: {
    availableCryptoCurrenciesAndNetworks: {
      deep: true,
      handler(selectedCurrenciesAndNetworks) {
        if (!this.availableCryptoCurrenciesAndNetworks.length) {
          return;
        }
        let payload = {};

        selectedCurrenciesAndNetworks.forEach(currency => {
          const hasAtLeastOneNetworkSelected = currency.networks.some((network) => network.enabled);
          if (!hasAtLeastOneNetworkSelected) {
            currency.enabled = false;
          }

          if (currency.enabled) {
            payload[currency.abbreviation] = currency.networks.filter(network => network.enabled).map(network => network.name);
          }
        });

        this.$emit("depositCurrenciesWithNetworksSelected", payload);
      }
    },
    selectAll(val) {
      this.availableCryptoCurrenciesAndNetworks.map(currency => {
        currency.enabled = val;
        currency.networks = currency.networks.map(network => {
          network.enabled = val;

          return network;
        });

        return currency;
      });
    }
  },
  methods: {
    handleSwitch(value, currency) {
      if (value) {
        this.availableCryptoCurrenciesAndNetworks.filter(c => c.abbreviation === currency)
            .map(target => target.networks.map(network => network.enabled = true))
      } else {
        this.availableCryptoCurrenciesAndNetworks.filter(c => c.abbreviation === currency)
            .map(target => target.networks.map(network => network.enabled = false))
      }
    },
    generateLabel(currency) {
      return `${currency.name} (${currency.abbreviation})`;
    },
  },
  mounted() {
    const hasPreselectedCurrencies = Boolean(this.preselectedDepositCurrenciesAndNetworks);
    this.availableCryptoCurrenciesAndNetworks = this.supportedDepositCurrencies.map((currency) => {
      const currencyAbbreviation = currency.abbreviation;
      const currencyEnabled = hasPreselectedCurrencies
          ? !!this.preselectedDepositCurrenciesAndNetworks[currencyAbbreviation]
          : false;

      return {
        name: currency.name,
        abbreviation: currencyAbbreviation,
        enabled: currencyEnabled,
        networks: currency.supportedNetworks.map(network => {
          const preselectedNetworks = currencyEnabled ? this.preselectedDepositCurrenciesAndNetworks[currencyAbbreviation] : [];

          return {
            name: network,
            enabled: preselectedNetworks.includes(network),
          }
        })
      }
    });
  }
};
</script>