import axios from "../axios.service";

const DEPOSITS = "sortBy=created&limit=25";
const TRANSFERS = "";
const UNALLOCATED = "limit=25";

class Payment {
  constructor() {
    this.businessId = null;
  }

  async fetchOrganisationMetadata() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/organisations/metadata`);
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  setID(id) {
    this.businessId = id;
    return this;
  }

  async deposits(payload = DEPOSITS, id = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}businesses/${id}/payments?`, { params: payload });
      return data.payments;
    } catch (error) {
      throw error.response.data.rejection;
    }
  }

  async withdrawals(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}withdrawals?`, { params: payload });
      return data.withdrawals;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async settlements(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/withdrawals/fiat/${this.businessId}`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  // async transfers(payload = TRANSFERS) {
  //   try {
  //     const internal = await this.internal(payload);
  //     const unassociated = await this.unassociated(payload);
  //
  //     const unassociatedExtendedToDebitAndCredit = [];
  //     unassociated.forEach((deposit) =>
  //       unassociated.push(
  //         { ...deposit, transferType: "CREDIT" },
  //         {
  //           ...deposit,
  //           transferType: "DEBIT",
  //           amount: deposit.feeAmount,
  //           depositLabel: `${deposit.depositLabel} Finrax Fee`,
  //         }
  //       )
  //     );
  //
  //     return [...internal, ...unassociatedExtendedToDebitAndCredit].sort((a, b) => b.createdAt - a.createdAt);
  //   } catch (error) {
  //     throw error.response.data.message;
  //   }
  // }

  async internalTransfers(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/internal/${this.businessId}/transfer?`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async unassociatedDeposits(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/deposits/unassociated/${this.businessId}?`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async manualLedgerMovements(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/accounting-ledger?`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async fiatDeposits(payload = this.businessId) {
    try {
      const regularFiatDeposits = await this.fiatTopUps(payload);
      const WLFiatDeposits = await this.fiatTopUpsWL(payload);

      return [...regularFiatDeposits, ...WLFiatDeposits];
    } catch (e) {
      throw e;
    }
  }

  async fiatTopUps(payload = this.businessId) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/deposits/fiat/${this.businessId}`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async fiatTopUpsWL(payload = this.businessId) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_URI}payment/whitelabel-fiat-deposits?businessId=${this.businessId}`,
        {
          params: payload,
        }
      );
      return data.map((deposit) => {
        return {
          ...deposit,
          status: "RECEIVED",
          clientLabel: deposit.reference,
          expectedAmount: "-",
          receivedAmount: deposit.amount,
          initiatedBy: "-",
          receivedAt: deposit.createdAt,
        };
      });
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async unallocated(payload = UNALLOCATED) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}deposits/unallocated?${payload}`);
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async getBalanceConversionMetadata(payload) {
    const amount = payload.amount ? `&amount=${payload.amount}` : "";
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_URI}payment/balance/convert/indicative?businessId=${payload.businessId}&type=${payload.type}${amount}`
      );
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async createBalanceConversion(payload, token) {
    try {
      axios.set2FAHeader(token);
      const { data } = await axios.post(`${process.env.VUE_APP_URI}payment/balance/convert`, payload);
      return data;
    } catch (error) {
      throw error.response.data.message;
    } finally {
      axios.remove2FAHeader();
    }
  }

  async executeBalanceConversion(id) {
    try {
      return await axios.patch(`${process.env.VUE_APP_URI}payment/balance/convert/${id}`);
    } catch (error) {
      throw error.response.data.message;
    }
  }

  async getBalanceConversions(payload) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URI}payment/balance/convert/${this.businessId}`, {
        params: payload,
      });
      return data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
}

export default new Payment();
