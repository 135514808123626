export const CHANGE_AUTH_STATUS = "CHANGE_AUTH_STATUS";
export const ACTION_CHANGE_AUTH_STATUS = "ACTION_CHANGE_AUTH_STATUS";
export const SET_USER_CONFIG = "SET_USER_CONFIG";
export const ACTION_IS_LOGGED_IN = "ACTION_IS_LOGGED_IN";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const ACTION_LOGOUT = "ACTION_LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const ACTION_LOGIN = "ACTION_LOGIN";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const RATE_LIMIT = "RATE_LIMIT";
export const ACTION_SESSION_EXPIRED = "ACTION_SESSION_EXPIRED";
export const ACTION_RATE_LIMIT = "ACTION_RATE_LIMIT";
export const EDIT_SENDER_INFO = "EDIT_SENDER_INFO";
export const ACTION_EDIT_SENDER_INFO = "ACTION_EDIT_SENDER_INFO";
export const ACTION_HAS_CHOSEN_ORGANISATION = "ACTION_HAS_CHOSEN_ORGANISATION";
export const HAS_CHOSEN_ORGANISATION = "HAS_CHOSEN_ORGANISATION";
