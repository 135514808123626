/* Configure logo, favicon, styles, document title based on current domain
 * */

const MALDOPAY_ORGANISATION_NAME = "MaldoPay";
const MALDOPAY_SECRET_BUSINESS_ID = "3b486c7a-497f-42a0-abce-993292f8962e";
const organisationsWithGbpSettlement = ["cf1e67d0-46f6-4a00-8370-090a5087c80f"];
const organisationsWithUsdSettlement = ["cf1e67d0-46f6-4a00-8370-090a5087c80f"];
const organisationsWithMassWithdrawals = [
  "cf1e67d0-46f6-4a00-8370-090a5087c80f",
  "8fb6cc2c-593e-48db-b0d5-ca6c0b0b79b6",
  "86cd90a3-ff9b-45a7-be2a-6c8b3b4ac1c0",
  "9ec5982f-117c-4511-852b-d39317aa3160",
];

const { FINRAX_LT, ALPINE_PAY, B2BPAY, VIRAPAY, MALDO, AXOM, DIGITAL_FIN_PAY, MONOLO } = Object.freeze({
  FINRAX_LT: "FINRAX_LT",
  ALPINE_PAY: "ALPINE_PAY",
  B2BPAY: "B2BPAY",
  VIRAPAY: "VIRAPAY",
  MALDO: "MALDO",
  AXOM: "AXOM",
  DIGITAL_FIN_PAY: "DIGITAL_FIN_PAY",
  MONOLO: "MONOLO",
});

const businessPermissions = ["GET_BUSINESS", "GET_BUSINESSES"];
const balancesStatisticsPermissions = ["GET_STATISTICS", "GET_BALANCE", "GET_BALANCES"];

const AppConfig = {
  showInfoSections: process.env.VUE_APP_DOMAIN === FINRAX_LT,
  loadedDomain: process.env.VUE_APP_DOMAIN,
  loadDomainLogo() {
    switch (process.env.VUE_APP_DOMAIN) {
      case ALPINE_PAY:
        return "logo-alpine_pay";
      case B2BPAY:
        return "logo-b2bpay";
      case VIRAPAY:
        return "logo-virapay";
      case MALDO:
        return "logo-maldo";
      case AXOM:
        return "logo-axom";
      case DIGITAL_FIN_PAY:
        return "logo-digitalfinpay";
      case MONOLO:
        return "logo-monolo";
      case FINRAX_LT:
        return "logo-finrax";
      default:
        return "logo-finrax";
    }
  },
  setTheme() {
    if (
      process.env.VUE_APP_DOMAIN === B2BPAY ||
      process.env.VUE_APP_DOMAIN === VIRAPAY ||
      process.env.VUE_APP_DOMAIN === MALDO ||
      process.env.VUE_APP_DOMAIN === AXOM
    ) {
      this.styles.primaryColor = "#1567A6";
      return this.styles;
    }
    return this.styles;
  },
  styles: {
    primaryColor: "#005550",
    userRole: "#15FAA8",
    sidebarColor: "#fff",
    sidebarHeaders: "#34C159",
    withdrawalColor: "#EF843D",
    positiveButtonAction: "#00c478",
    buttonTextColor: "#fff",
  },
  loadFavicon() {
    let src;
    if (this.loadedDomain === this.domains.FINRAX_LT) src = "/static/favicon-finrax.ico";
    if (this.loadedDomain === this.domains.ALPINE_PAY) src = "/static/favicon-alpine_pay.ico";
    if (this.loadedDomain === this.domains.B2BPAY) src = "/static/favicon-b2bpay.ico";
    if (this.loadedDomain === this.domains.MALDO) src = "/static/favicon-maldo.ico";
    if (this.loadedDomain === this.domains.VIRAPAY) src = "/static/favicon-virapay.ico";
    if (this.loadedDomain === this.domains.AXOM) src = "/static/favicon-axom.ico";
    if (this.loadedDomain === this.domains.DIGITAL_FIN_PAY) src = "/static/favicon-digitalfinpay.png";
    if (this.loadedDomain === this.domains.MONOLO) src = "/static/favicon-monolo.ico";
    let link = document.getElementById("dynamic-favicon");
    link.href = src;
    document.head.appendChild(link);
  },
  setDocumentTitle() {
    switch (process.env.VUE_APP_DOMAIN) {
      case ALPINE_PAY:
        document.title = "Alpine Pay Dashboard";
        break;
      case B2BPAY:
        document.title = "B2BPAY Dashboard";
        break;
      case VIRAPAY:
        document.title = "ViraPay Dashboard";
        break;
      case AXOM:
        document.title = "Axom Dashboard";
        break;
      case DIGITAL_FIN_PAY:
        document.title = "DigitalFinPay Dashboard";
        break;
      case MALDO:
        document.title = "MaldoCrypto Dashboard";
        break;
      case MONOLO:
        document.title = "Monolo Dashboard";
        break;
      case FINRAX_LT:
        document.title = "Finrax Dashboard";
        break;
      default:
        document.title = "Finrax Dashboard";
        break;
    }
  },
  domains: {
    FINRAX_LT,
    B2BPAY,
    VIRAPAY,
    MALDO,
    AXOM,
    DIGITAL_FIN_PAY,
    MONOLO,
  },
  customers: {
    MALDOPAY_ORGANISATION_NAME,
    MALDOPAY_SECRET_BUSINESS_ID,
    organisationsWithGbpSettlement,
    organisationsWithUsdSettlement,
    organisationsWithMassWithdrawals,
  },
  paymentType: {
    DEPOSITS: "Deposits",
    WITHDRAWALS: "Withdrawals",
    SETTLEMENTS: "Settlements",
    FIAT_TOP_UPS: "FiatTopUps",
    TRANSFERS: "Transfers",
    UNASSOCIATED_DEPOSITS: "UnassociatedDeposits",
    MANUAL_LEDGER_MOVEMENTS: "ManualLedgerMovements",
    STATISTICS: "Statistics",
    UNALLOCATED: "Unallocated",
    BALANCE_CONVERSIONS: "BalanceConversions",
  },
  getOverpayScenario(shouldRefund) {
    return shouldRefund ? this.overpayScenarios.refund : this.overpayScenarios.process;
  },
  overpayScenarios: {
    process: "PROCESS",
    refund: "EXCESS_REFUND",
  },
  userRoles: {
    SUPPORT: "SUPPORT",
    BACKOFFICE: "BACKOFFICE",
    MANAGER: "MANAGER",
    ADMIN: "ADMIN",
  },
  defaultPermissions: ["GET_ORGANISATION"],
  permissions: [
    {
      name: "Manage businesses",
      view: [],
      full: [...businessPermissions, ...balancesStatisticsPermissions, "CREATE_BUSINESS", "UPDATE_BUSINESS"],
    },
    {
      name: "View statistics",
      view: [],
      full: [...balancesStatisticsPermissions],
    },
    {
      name: "Manage payments",
      view: ["GET_PAYMENT", "GET_PAYMENTS", "INITIATE_CALLBACK", ...businessPermissions],
      full: [
        "GET_PAYMENT",
        "GET_PAYMENTS",
        "INITIATE_CALLBACK",
        ...businessPermissions,
        ...balancesStatisticsPermissions,
        "CREATE_PAYMENT",
      ],
    },
    {
      name: "Manage withdrawals",
      view: ["GET_WITHDRAWALS", ...businessPermissions],
      full: ["CREATE_WITHDRAWAL", "GET_WITHDRAWALS", ...businessPermissions, ...balancesStatisticsPermissions],
    },
    {
      name: "Manage internal transfers",
      view: ["GET_INTERNAL_TRANSFERS", "FETCH_ACCOUNTING_LEDGER", ...businessPermissions],
      full: [
        "GET_INTERNAL_TRANSFERS",
        "CREATE_INTERNAL_TRANSFER",
        "FETCH_ACCOUNTING_LEDGER",
        ...businessPermissions,
        ...balancesStatisticsPermissions,
      ],
    },
    {
      name: "Manage bank accounts",
      view: ["FETCH_BANK_METADATA", "FETCH_BANK_ACCOUNTS"],
      full: ["FETCH_BANK_METADATA", "FETCH_BANK_ACCOUNTS", "MANAGE_BANK_ACCOUNTS"],
    },
    {
      name: "Manage fiat top-ups",
      view: ["GET_FIAT_DEPOSITS", ...businessPermissions],
      full: [
        "GET_FIAT_DEPOSITS",
        "CREATE_FIAT_DEPOSIT",
        "FETCH_BANK_ACCOUNTS",
        ...businessPermissions,
        ...balancesStatisticsPermissions,
      ],
    },
    {
      name: "Manage bank settlements",
      view: ["GET_FIAT_WITHDRAWALS", ...businessPermissions],
      full: [
        "GET_FIAT_WITHDRAWALS",
        "CREATE_FIAT_WITHDRAWAL",
        "FETCH_BANK_METADATA",
        "FETCH_BANK_ACCOUNTS",
        ...businessPermissions,
        ...balancesStatisticsPermissions,
      ],
    },
    {
      name: "Manage balance conversions",
      view: ["GET_BALANCE_CONVERSIONS", ...businessPermissions],
      full: [
        "CREATE_BALANCE_CONVERSION",
        "GET_BALANCE_CONVERSIONS",
        ...businessPermissions,
        ...balancesStatisticsPermissions,
      ],
    },
    {
      name: "Manage API keys",
      view: ["GET_API_CREDENTIALS"],
      full: ["GET_API_CREDENTIALS", "CREATE_API_CREDENTIALS", "UPDATE_API_CREDENTIALS", "DELETE_API_CREDENTIALS"],
    },
    {
      name: "Manage whitelisted addresses",
      view: [],
      full: ["WHITELIST_ADDRESSES"],
    },
    {
      name: "Manage users and roles",
      view: [],
      full: ["MANAGE_ROLES", "CREATE_USER", "UPDATE_USER", "DELETE_USER", "GET_USERS"],
    },
  ],
  erc20tokens: [
    "ETH",
    "AION",
    "BAT",
    "BNT",
    "CVC",
    "ENJ",
    "FUN",
    "KNC",
    "LINK",
    "LRC",
    "MITH",
    "MTL",
    "OMG",
    "REP",
    "ZIL",
    "USDT",
    "USDC",
  ],
  cryptoSettlementCurrencies: ["USDT", "USDC", "BTC"],
  nonErc20tokens: ["BTC", "BCH", "LTC", "XLM", "XRP", "EOS", "DASH"],
  blockchainPathsMapping: {
    BTC: "bitcoin",
    BCH: "bitcoin-cash",
    LTC: "litecoin",
    XLM: "stellar",
    XRP: "ripple",
    EOS: "eos",
    DASH: "dash",
  },
  humanReadableNetworks: {
    MAIN: "Main network",
    BSC: "Binance Smart Chain (BEP20)",
    TRX: "Tron Network (TRC20)",
    ETH: "Ethereum network (ERC20)",
    SOL: "Solana network (SPL)",
  },
};

module.exports = AppConfig;
