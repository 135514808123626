<template>
  <v-card :style="styles" v-bind="$attrs" v-on="$listeners">
    <HelperOffset v-if="hasOffset" :inline="inline" :full-width="fullWidth" :offset="offset">
      <v-card
        v-if="!$slots.offset"
        :color="$AppConfig.setTheme().primaryColor"
        :elevation="elevation"
        class="v-card--material__header d-flex align-center"
        dark
        min-height="80">
        <slot v-if="!title && !text" name="header" />
        <div v-else class="px-3">
          <h4 class="title font-weight-400 mb-2" v-text="title" />
          <p class="category font-weight-light mb-0" v-text="text" />
        </div>
      </v-card>

      <slot v-else name="offset" />
    </HelperOffset>

    <v-card-text>
      <slot />
    </v-card-text>

    <v-card-actions v-if="$slots.actions" class="mx-3">
      <v-col class="text-right">
        <slot name="actions" />
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import HelperOffset from "./Offset";

export default {
  name: "MaterialCard",
  components: {
    HelperOffset,
  },

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: "secondary",
    },
    elevation: {
      type: [Number, String],
      default: 10,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: [Number, String],
      default: 24,
    },
    title: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text;
    },
    styles() {
      if (!this.hasOffset) return null;

      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      };
    },
  },
};
</script>