var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.load)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-0",staticStyle:{"font-size":"14px","color":"#348bde"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){_vm.$router.push('/businesses').catch(function (err) {})}}},[_vm._v("Businesses")]),_c('span',{staticClass:"ml-1",staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s((" / " + (_vm.generateTitle ? _vm.generateTitle.name : "")))+" ")])]),_c('v-col',{staticClass:"d-flex mb-10 flex-wrap",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.businessesDark")]),_vm._v(" "),_c('v-flex',[_vm._v(_vm._s(_vm.generateTitle ? _vm.generateTitle.name : ""))]),_c('v-flex',{staticClass:"mt-1",staticStyle:{"width":"100%"}},[_vm._v("ID:"+_vm._s(_vm.generateTitle ? _vm.generateTitle.id : ""))])],1),_c('v-col'),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-col',{staticClass:"d-flex justify-center align-self-center",attrs:{"cols":"12","md":"12"}},[_c('v-item-group',{staticClass:"d-flex justify-center flex-wrap",attrs:{"mandatory":true,"multiple":false},model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}},[(_vm.userPermissions && _vm.userPermissions.includes('GET_PAYMENT'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"height":"40","dark":"","text":!active,"min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Crypto Deposits")])])]}}],null,false,3402554459)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_WITHDRAWALS'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Crypto Withdrawals")])])]}}],null,false,960521156)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_FIAT_DEPOSITS'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Fiat Deposits")])])]}}],null,false,2261702653)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_FIAT_WITHDRAWALS'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Fiat Withdrawals")])])]}}],null,false,3385845698)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_INTERNAL_TRANSFERS'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Internal Transfers")])])]}}],null,false,4215836571)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_INTERNAL_TRANSFERS'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Unassociated Deposits")])])]}}],null,false,54406444)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('FETCH_ACCOUNTING_LEDGER'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Manual Operations")])])]}}],null,false,4216052088)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_BALANCE_CONVERSIONS'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"height":"40","dark":"","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Balance conversions")])])]}}],null,false,2097124905)}):_vm._e(),(_vm.userPermissions && _vm.userPermissions.includes('GET_BALANCES'))?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"d-flex align-center wrap",staticStyle:{"text-transform":"capitalize"},attrs:{"color":_vm.$AppConfig.setTheme().primaryColor,"text":!active,"dark":"","height":"40","min-width":"124"},on:{"click":[toggle,_vm.changeRoute]}},[_c('div',[_vm._v("Statistics")])])]}}],null,false,3969141857)}):_vm._e()],1)],1),_c('router-view',{attrs:{"isMobile":_vm.isMobile,"mobileBreakpoint":_vm.mobileBreakpoint,"businessName":_vm.businessName,"supportedDepositCurrencies":_vm.supportedDepositCurrencies}})],1)],1):_vm._e(),_c('Snackbar',{attrs:{"config":_vm.snackbarConfig}}),_c('v-overlay',{attrs:{"absolute":"","z-index":"222","opacity":".7","value":_vm.load}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }