const CONFIG = "config";
import Enigma from "../services/encryption.service";

const TokenService = {
  saveConfig(obj) {
    const config = Enigma.encryptObj(obj);
    sessionStorage.setItem(CONFIG, config);
  },
  getConfig(option) {
    if (sessionStorage.getItem(CONFIG)) {
      let res = Enigma.decryptObj(sessionStorage.getItem(CONFIG));

      return res[option];
    }
  },
  editConfig(option, val) {
    if (sessionStorage.getItem(CONFIG)) {
      let res = Enigma.decryptObj(sessionStorage.getItem(CONFIG));
      res[option] = val;
      let edited = res;

      this.saveConfig(edited);
    }
  },

  removeConfig() {
    sessionStorage.removeItem(CONFIG);
  },
};

export { TokenService };
