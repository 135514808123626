var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"380"},on:{"click:outside":function($event){return _vm.$emit('closeRequest', true)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"d-flex flex-column align-center pa-3"},[(_vm.done)?_c('v-img',{staticClass:"mt-10",attrs:{"width":"100","src":require("../../assets/images/success_tick.png")}}):_vm._e(),(_vm.failed)?_c('v-img',{staticClass:"mt-10",attrs:{"width":"100","src":require("../../assets/images/exclamation-circle.svg")}}):_vm._e(),_c('v-card-title',{staticClass:"text-center",style:([
          _vm.done
            ? {
                color: '#242539',
                fontSize: '18px',
                lineHeight: '22px',
                wordBreak: 'break-word',
              }
            : {
                color: '#242539',
                fontSize: '18px',
                lineHeight: '22px',
                wordBreak: 'break-word',
              } ])},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{staticClass:"mt-10 mb-5",attrs:{"dark":"","color":_vm.$AppConfig.setTheme().primaryColor,"width":"200","height":"50"},on:{"click":function($event){return _vm.$emit('closeRequest', true)}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }